<template>
  <div class="app-list-page">
    <header class="header">
      <div class="top-bar">
        <div class="container">
          <div class="logo">
            <img
              src="@/assets/optionsTechnology-logo.svg"
              alt="Options IT Logo"
            />
          </div>
        </div>
      </div>
      <div class="nav-bar" v-if="isLoggedIn">
        <div class="container">
          <NavBar />
        </div>
      </div>
    </header>

    <div class="content">
      <!-- Pipeline Dropdown Section -->
      <div class="pipelines-dropdown">
        <label for="pipelineSelect">Choose Pipeline:</label>
        <select
          v-model="selectedPipeline"
          id="pipelineSelect"
          class="pipeline-select"
        >
          <option
            v-for="pipeline in pipelines"
            :key="pipeline.id"
            :value="pipeline"
          >
            {{ pipeline.name }} - {{ formatFolder(pipeline.folder) }}
          </option>
        </select>
        <button class="view-yaml-button" @click="viewYaml">View YAML</button>
        <!-- New Button to Update YAML File -->
        <button class="update-yaml-button" @click="updateYamlFile">
          Update YAML with Apps
        </button>
      </div>

      <!-- YAML Content Section -->
      <div class="yaml-content" v-if="yamlContent">
        <h2>App Deploy - Yaml File Contents</h2>
        <div class="yaml-content-wrapper">
          <pre>{{ yamlContent }}</pre>
        </div>
        <button class="clear-yaml-button" @click="yamlContent = null">
          Close Yaml
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/views/components/NavBar.vue";
import { msalInstance } from "@/auth.js";
import {
  fetchPipelinesWithFilter,
  fetchYamlFile,
  updateYamlFileContent,
  getTableContents,
} from "@/azure-devops-api.js";
import { mapState } from "vuex";

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      pipelines: [],
      selectedPipeline: null,
      yamlContent: null,
    };
  },
  computed: mapState(["isLoggedIn"]),
  beforeRouteEnter(to, from, next) {
    if (!msalInstance.getAllAccounts().length) {
      next("/login");
    } else {
      next();
    }
  },
  async created() {
    try {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length) {
        const accessTokenRequest = {
          account: accounts[0],
          scopes: ["499b84ac-1321-427f-aa17-267ca6975798/user_impersonation"],
        };
        const response = await msalInstance.acquireTokenSilent(
          accessTokenRequest
        );
        this.pipelines = await fetchPipelinesWithFilter(response.accessToken);

        this.pipelines.sort((a, b) => {
  const folderA = a.folder ? a.folder.replace("\\", "").trim() : "";
  const folderB = b.folder ? b.folder.replace("\\", "").trim() : "";
  
  return folderA.localeCompare(folderB, undefined, { sensitivity: 'base' });
});
      }
    } catch (error) {
      console.error("Error fetching pipelines:", error);
    }
  },
  methods: {
    formatFolder(folder) {
      return folder.replace("\\", "").trim();
    },
    async viewYaml() {
      try {
        if (!this.selectedPipeline) {
          alert("Please select a pipeline from the dropdown menu.");
          return;
        }

        const accounts = msalInstance.getAllAccounts();
        if (!accounts || !accounts.length) {
          throw new Error("User is not logged in");
        }

        const accessTokenRequest = {
          account: accounts[0],
          scopes: ["499b84ac-1321-427f-aa17-267ca6975798/user_impersonation"],
        };
        const response = await msalInstance.acquireTokenSilent(
          accessTokenRequest
        );
        const accessToken = response.accessToken;

        // Extract client code from the selected pipeline's folder
        const clientCode = this.formatFolder(this.selectedPipeline.folder);

        const repoId = "e8c26efa-669f-4012-a022-8ac25508de46"; // Replace with your repository ID
        const yamlFilePath = `/Client_Yaml_Files/${clientCode.toLowerCase()}-azure-pipelines.yml`; // Assuming this path structure
        const yamlFile = await fetchYamlFile(accessToken, repoId, yamlFilePath);

        // Check if content is base64 encoded
        const isBase64 = (content) => {
          try {
            return btoa(atob(content)) === content;
          } catch (error) {
            return false;
          }
        };

        // If content is base64 encoded, decode it; otherwise, use as is
        this.yamlContent = isBase64(yamlFile.content)
          ? atob(yamlFile.content)
          : yamlFile.content;
      } catch (error) {
        console.error(
          `Error fetching YAML file for pipeline ${this.selectedPipeline.name}:`,
          error
        );
      }
    },
    async updateYamlFile() {
      try {
        if (!this.selectedPipeline) {
          alert("Please select a pipeline from the dropdown menu.");
          return;
        }

        const accounts = msalInstance.getAllAccounts();
        if (!accounts || !accounts.length) {
          throw new Error("User is not logged in");
        }

        const accessTokenRequest = {
          account: accounts[0],
          scopes: ["499b84ac-1321-427f-aa17-267ca6975798/user_impersonation"],
        };
        const response = await msalInstance.acquireTokenSilent(
          accessTokenRequest
        );
        const accessToken = response.accessToken;

        // Extract client code from the selected pipeline's folder
        const clientCode = this.formatFolder(this.selectedPipeline.folder);
        const TableName = `${clientCode.toLowerCase()}WorkstationApps`;

        // Fetch the application list for the client from the Azure Storage Table
        const tableContents = await getTableContents(TableName);

        // Extract the list of applications with status check
        const appList = tableContents.map((item) => {
          const appName = item.AppName;
          const status =
            item.Status === "Enabled" ? `- ${appName}` : `#- ${appName}`; // Correcting to '#-' for Disabled apps
          return status;
        });

        // Update the YAML file content with the conditional comments
        const updatedYamlContent = `
trigger: none

parameters:
  - name: AppList
    type: object
    default:
${appList.map((app) => `        ${app}`).join("\n")}

variables:
  - group: ${clientCode}-VG-01
  - group: ${clientCode}-KV-VG-01
  - group: GEN-KV-VG-01
  - group: GEN-VG-01

pool:
  name: $(PoolName)
  demands:
    - agentOS -equals $(agentOS)

stages:
  - \${{ each app in parameters.AppList }}:
    - stage: ConnectToClientTenant_Stage_\${{ app }}
      jobs:
        - job: ConnectToClientTenant_Job
          steps:
            - task: PowerShell@2
              displayName: "Connect to Client Azure"
              name: "Connect_To_Client_Tenant_Info_\${{ app }}"
              inputs:
                targetType: "filePath"
                filePath: "$(Build.SourcesDirectory)/ConnectToClientTenant.ps1"
                arguments: "-ClientTenantID $(ClientTenantID) -AppDeployAppID $(AppDeployAppID) -AppDeployAppSecret $(${clientCode}AppDeployAppSecret)"

    - stage: QueryAzureTableStorage_Stage_\${{ app }}
      jobs:
        - job: QueryAzureTableStorage_Job
          steps:
            - task: PowerShell@2
              displayName: "Query Azure Table Storage"
              name: "Query_Azure_Table_Storage_\${{ app }}"
              inputs:
                targetType: filePath
                filePath: "$(Build.SourcesDirectory)/GetAndQueryStorageTablev2.ps1"
                arguments: "-StorageAccountName $(StorageAccountName) -storageAccountKey $(CYC-StorageAccountKey) -endpointSuffix $(endpointSuffix) -TableName $(TableName) -AppName \${{ app }}"

    - stage: QueryEvergreenAppInfo_Stage_\${{ app }}
      dependsOn: QueryAzureTableStorage_Stage_\${{ app }}
      jobs:
        - job: QueryEvergreenAppInfo_Job
          variables:
            SearchQueryReturned: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbURI']]
          steps:
            - task: PowerShell@2
              displayName: "Query Evergreen App Info"
              name: "Query_Evergreen_App_Info_\${{ app }}"
              inputs:
                targetType: filePath
                filePath: "$(Build.SourcesDirectory)/GetEverGreenAppInfov0.2.ps1"
              env:
                AppName: \${{ app }}
                EvergreenSearchType: $(SearchType)
                AzTbURI: $(SearchQueryReturned)

    - stage: CompareSoftwareVersions_Stage_\${{ app }}
      dependsOn:
        - QueryAzureTableStorage_Stage_\${{ app }}
        - QueryEvergreenAppInfo_Stage_\${{ app }}
      jobs:
        - job: CompareSoftwareVersions_\${{ app }}_Job
          variables:
            SearchQueryReturned: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbVersion']]
            EvergreenSearchQueryReturned: $[stageDependencies.QueryEvergreenAppInfo_Stage_\${{ app }}.QueryEvergreenAppInfo_Job.outputs['Query_Evergreen_App_Info_\${{ app }}.EvergreenSearchQueryReturned']]
          steps:
            - task: PowerShell@2
              displayName: "Compare Software Versions"
              name: "CompareSoftwareVersions_\${{ app }}"
              inputs:
                targetType: filePath
                filePath: "$(Build.SourcesDirectory)/CompareSoftwareVersions.ps1"
                arguments: "-AppName \${{ app }} -AzTableVersion $(SearchQueryReturned) -EvergreenVersion $(EvergreenSearchQueryReturned)"

    - stage: Choco_Stage_\${{ app }}
      dependsOn:
        - CompareSoftwareVersions_Stage_\${{ app }}
        - QueryAzureTableStorage_Stage_\${{ app }}
        - QueryEvergreenAppInfo_Stage_\${{ app }}
      jobs:
        - job: Choco_Job
          condition: eq(stageDependencies.CompareSoftwareVersions_Stage_\${{ app }}.CompareSoftwareVersions_\${{ app }}_Job.outputs['CompareSoftwareVersions_\${{ app }}.SoftwareStatus'], 'UpdateRequired')
          variables:
            SoftwareStatus: $[stageDependencies.CompareSoftwareVersions_Stage_\${{ app }}.CompareSoftwareVersions_\${{ app }}_Job.outputs['CompareSoftwareVersions_\${{ app }}.SoftwareStatus']]
            SearchQueryReturned: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbURI']]
            EvergreenSearchQueryReturned: $[stageDependencies.QueryEvergreenAppInfo_Stage_\${{ app }}.QueryEvergreenAppInfo_Job.outputs['Query_Evergreen_App_Info_\${{ app }}.EvergreenSearchQueryReturned']]
            EvergreenSearchQueryURI: $[stageDependencies.QueryEvergreenAppInfo_Stage_\${{ app }}.QueryEvergreenAppInfo_Job.outputs['Query_Evergreen_App_Info_\${{ app }}.EvergreenSearchQueryReturnedURI']]
            SearchQueryInstallFileType: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbInstallFileType']]
            SearchQueryUninstallFileType: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbUninstallFileType']]
            SearchQueryInstallCmd: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbInstall']]
            SearchQuerySoftwareName: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbSoftwareName']]
            SearchQueryCheckSum: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbCheckSum']]
            SearchQueryCheckSum64: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbCheckSum64']]
            SearchQueryDescription: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbDescription']]
            SearchQueryPublisher: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbPublisher']]
            SearchQueryProjectURL: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbProjectURL']]
            SearchQueryDownloadFileType: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbDownloadFileType']]
            SearchQueryUninstall: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbUninstall']]
            SearchQueryUninstallerLocation: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbUninstallerLocation']]
            SearchQueryExitCode: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbExitCode']]
            SearchQueryIconPath: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbIconPath']]
          steps:
            - task: PowerShell@2
              displayName: "Choco Tasks"
              name: "Running_Choco_Checks_\${{ app }}"
              inputs:
                targetType: filePath
                filePath: "$(Build.SourcesDirectory)/ChocoTasksCheckv0.2.ps1"
              env:
                AppName: \${{ app }}
                SoftwareStatus: $(SoftwareStatus)
                DownloadURI: $(EvergreenSearchQueryURI) 
                AzTbURI: $(SearchQueryReturned)
                UninstallerLocation: $(SearchQueryUninstallerLocation)
                UninstallRequirement: $(SearchQueryUninstall)
                NewVersionNumber: $(EvergreenSearchQueryReturned)
                InstallFileType: $(SearchQueryInstallFileType)
                UninstallFileType: $(SearchQueryUninstallFileType)
                InstallCmd:  $(SearchQueryInstallCmd)
                SoftwareName: $(SearchQuerySoftwareName)
                CheckSum: $(SearchQueryCheckSum)
                CheckSum64: $(SearchQueryCheckSum64)
                Description: $(SearchQueryDescription)
                Publisher: $(SearchQueryPublisher)
                Title: \${{ app }}
                Summary: \${{ app }}
                Tags: \${{ app }}
                ProjectURL: $(SearchQueryProjectURL)
                DownloadFileType: $(SearchQueryDownloadFileType)
                ExitCode: $(SearchQueryExitCode)
                ClientID: $(CyanicClientId)
                ClientSecret: $(CYC-StorageTableClientSecret)
                TenantID: $(CyanicTenantID)

        - job: Choco_Pack_Job
          dependsOn: Choco_Job
          steps:
            - task: PowerShell@2
              displayName: "Choco Pack Tasks"
              name: "Running_Choco_Pack_Tasks_\${{ app }}"
              inputs:
                targetType: filePath
                filePath: "$(Build.SourcesDirectory)/ChocoPackTasks.ps1"
                arguments: "-AppName \${{ app }}"

        - job: Choco_Install_Job
          dependsOn: Choco_Pack_Job
          variables:
            SearchQueryIconPath: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbIconPath']]
          steps:
            - task: PowerShell@2
              displayName: "Choco Install Tasks"
              name: "Running_Choco_Install_Tasks_\${{ app }}"
              inputs:
                targetType: filePath
                filePath: "$(Build.SourcesDirectory)/Choco-install.ps1"
              env:
                AppName: \${{ app }}
                IconPath: $(SearchQueryIconPath)

        - job: Choco_Uninstall_Job
          dependsOn: Choco_Install_Job
          variables:
            SearchQueryUninstall: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbUninstall']]
          steps:
            - task: PowerShell@2
              displayName: "Choco Uninstall Tasks"
              name: "Running_Choco_Uninstall_Tasks_\${{ app }}"
              inputs:
                targetType: filePath
                filePath: "$(Build.SourcesDirectory)/Choco-uninstall.ps1"
              env:
                AppName: \${{ app }}
                UninstallRequirement: $(SearchQueryUninstall)

        - job: Choco_Push_Job
          dependsOn:
            - Choco_Install_Job
            - Choco_Uninstall_Job
          steps:
            - task: PowerShell@2
              displayName: "Choco Push Tasks"
              name: "Running_Choco_Push_Tasks_\${{ app }}"
              inputs:
                targetType: filePath
                filePath: "$(Build.SourcesDirectory)/ChocoPushTasks.ps1"
                arguments: "-AppName \${{ app }} -NexusProdRepo $(NexusProdRepo) -ClientRepo $(ClientRepo) -APIKey $(PRODAPIKey)"

    - stage: UpdateAzureStorageTable_Stage_\${{ app }}
      dependsOn:
        - QueryAzureTableStorage_Stage_\${{ app }}
        - QueryEvergreenAppInfo_Stage_\${{ app }}
        - Choco_Stage_\${{ app }}
        - CompareSoftwareVersions_Stage_\${{ app }}
      jobs:
        - job: UpdateAzureStorageTable_Job
          condition: eq(stageDependencies.CompareSoftwareVersions_Stage_\${{ app }}.CompareSoftwareVersions_\${{ app }}_Job.outputs['CompareSoftwareVersions_\${{ app }}.SoftwareStatus'], 'UpdateRequired')
          variables:
            SearchQueryReturned: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbVersion']]
            EvergreenSearchQueryReturned: $[stageDependencies.QueryEvergreenAppInfo_Stage_\${{ app }}.QueryEvergreenAppInfo_Job.outputs['Query_Evergreen_App_Info_\${{ app }}.EvergreenSearchQueryReturned']]
          steps:
            - task: PowerShell@2
              displayName: "Update App Version Number"
              name: "Update_App_Version_Number_\${{ app }}"
              inputs:
                targetType: filePath
                filePath: "$(Build.SourcesDirectory)/UpdateStorageTablev2.ps1"
                arguments: "-AppName \${{ app }} -StorageAccountName $(StorageAccountName) -storageAccountKey $(CYC-StorageAccountKey) -endpointSuffix $(endpointSuffix) -TableName $(TableName) -PreviousVersionNumber $(SearchQueryReturned) -EvergreenVersion $(EvergreenSearchQueryReturned)"

    - stage: IntuneAppDeployment_Stage_\${{ app }}
      dependsOn:
        - ConnectToClientTenant_Stage_\${{ app }}
        - QueryAzureTableStorage_Stage_\${{ app }}
        - QueryEvergreenAppInfo_Stage_\${{ app }}
        - UpdateAzureStorageTable_Stage_\${{ app }}
        - CompareSoftwareVersions_Stage_\${{ app }}
      jobs:
        - job: IntuneAppDeployment_Job
          condition: eq(stageDependencies.CompareSoftwareVersions_Stage_\${{ app }}.CompareSoftwareVersions_\${{ app }}_Job.outputs['CompareSoftwareVersions_\${{ app }}.SoftwareStatus'], 'UpdateRequired')
          variables:
            Tokenheaders: $[stageDependencies.ConnectToClientTenant_Stage_\${{ app }}.ConnectToClientTenant_Job.outputs['Connect_To_Client_Tenant_Info_\${{ app }}.AUTH_TOKEN']]
            Description: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbDescription']]
            Publisher: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbPublisher']]
            SearchQueryReturned: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbVersion']]
            EvergreenSearchQueryReturned: $[stageDependencies.QueryEvergreenAppInfo_Stage_\${{ app }}.QueryEvergreenAppInfo_Job.outputs['Query_Evergreen_App_Info_\${{ app }}.EvergreenSearchQueryReturned']]
            AssignmentScopeRequired: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbAssignmentScopeRequired']]
            AssignmentScopeAvailable: $[stageDependencies.QueryAzureTableStorage_Stage_\${{ app }}.QueryAzureTableStorage_Job.outputs['Query_Azure_Table_Storage_\${{ app }}.SearchQueryAzTbAssignmentScopeAvailable']]
          steps:
            - task: PowerShell@2
              displayName: "Intune App Deployment"
              inputs:
                targetType: filePath
                filePath: "$(Build.SourcesDirectory)/Intune_App_Deployment_Files/${clientCode}-IntuneAppDeployment.ps1"
              env:
                Authentication: $(Tokenheaders)
                AppName: \${{ app }}
                Description: $(Description)
                Publisher: $(Publisher)
                Version: $(EvergreenSearchQueryReturned)
                PreviousVersion: $(SearchQueryReturned)
                AssignmentScopeRequired: $(AssignmentScopeRequired)
                AssignmentScopeAvailable: $(AssignmentScopeAvailable)
`;

        // Update the YAML file in Azure DevOps repository
        const repoId = "e8c26efa-669f-4012-a022-8ac25508de46"; // Replace with your repository ID
        const yamlFilePath = `/Client_Yaml_Files/${clientCode.toLowerCase()}-azure-pipelines.yml`; // Assuming this path structure
        await updateYamlFileContent(
          accessToken,
          repoId,
          yamlFilePath,
          updatedYamlContent
        );

        // Refresh the YAML content on the page
        this.yamlContent = updatedYamlContent;
        alert("YAML file updated successfully.");
      } catch (error) {
        console.error("Error updating YAML file:", error);
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Roboto:wght@400;500;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.header {
  background: #0e223b;
  color: #fff;
}

.top-bar {
  background: #ffffff;
  padding: 20px 0;
  text-align: center;
}

.top-bar .logo {
  text-align: left;
}

.top-bar .logo img {
  width: 150px;
}

.nav-bar {
  background: #0e223b;
  padding: 10px 0;
}

.nav-bar a {
  color: #fff;
  margin: 0 15px;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-bar a:hover {
  color: #38b35f;
}

.content {
  width: 90%;
  margin: 20px auto;
  text-align: left; /* Align text to the left */
}

.pipelines-dropdown {
  width: 70%; /* Increase the width */
  margin: 0 auto 20px auto;
  background-color: #f5f5f5;
  padding: 20px; /* Increase padding */
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Roboto", sans-serif;
}

.pipeline-select {
  width: 200px; /* Set dropdown width */
  padding: 10px;
  margin-left: 10px; /* Space between label and dropdown */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
}

.view-yaml-button,
.update-yaml-button {
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 20px; /* Space between dropdown and button */
}

.view-yaml-button:hover,
.update-yaml-button:hover {
  background-color: #0056b3;
}

/* YAML content styling */
.yaml-content {
  width: 70%; /* Increase the width */
  margin: 20px auto;
  background-color: #f8f9fa;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.yaml-content-wrapper {
  max-height: 500px;
  overflow-y: auto;
  background-color: #fff;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.clear-yaml-button {
  background-color: #d9534f;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.clear-yaml-button:hover {
  background-color: #c9302c;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: "Roboto", sans-serif; /* Set font to Roboto */
  text-align: left; /* Align text to the left */
}
</style>
