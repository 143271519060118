<template>
  <div class="pipelines-page">
    <header class="header">
      <div class="top-bar">
        <div class="container">
          <div class="logo">
            <img
              src="@/assets/optionsTechnology-logo.svg"
              alt="Options IT Logo"
            />
          </div>
        </div>
      </div>
      <div class="nav-bar" v-if="isLoggedIn">
        <div class="container">
          <NavBar />
        </div>
      </div>
    </header>
    <div class="container">
      <div class="pipelines-list">
        <h1>Azure DevOps Pipelines</h1>
        <div
          class="pipeline-item"
          v-for="pipeline in pipelines"
          :key="pipeline.id"
        >
          <div class="pipeline-info">
            <div><strong>Project:</strong> {{ pipeline.name }}</div>
            <div>
              <strong>Client:</strong> {{ formatFolder(pipeline.folder) }}
            </div>
          </div>
          <div class="pipeline-actions">
            <button
              class="run-pipeline-button"
              @click="runPipeline(pipeline.id)"
            >
              Run Pipeline
            </button>
            <div v-if="messages[pipeline.id]" :class="['message', messages[pipeline.id].type]">
              {{ messages[pipeline.id].text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/views/components/NavBar.vue";
import { msalInstance } from "@/auth.js";
import { fetchPipelinesWithFilter, runPipeline } from "@/azure-devops-api.js";
import { mapState } from "vuex";

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      pipelines: [],
      messages: {}, // Object to store messages for each pipeline by ID
    };
  },
  computed: mapState(["isLoggedIn"]),
  beforeRouteEnter(to, from, next) {
    if (!msalInstance.getAllAccounts().length) {
      next("/login");
    } else {
      next();
    }
  },
  async created() {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length) {
      const accessTokenRequest = {
        account: accounts[0],
        scopes: ["499b84ac-1321-427f-aa17-267ca6975798/user_impersonation"],
      };
      try {
        const response = await msalInstance.acquireTokenSilent(
          accessTokenRequest
        );
        this.pipelines = await fetchPipelinesWithFilter(response.accessToken);
      } catch (error) {
        console.error("Error fetching pipelines:", error);
      }
    }
  },
  methods: {
  formatFolder(folder) {
    return folder.replace("\\", "").trim();
  },
  async runPipeline(pipelineId) {
    try {
      const accounts = msalInstance.getAllAccounts();
      if (!accounts || !accounts.length) {
        throw new Error("User is not logged in");
      }

      const accessTokenRequest = {
        account: accounts[0],
        scopes: ["499b84ac-1321-427f-aa17-267ca6975798/user_impersonation"],
      };
      const response = await msalInstance.acquireTokenSilent(
        accessTokenRequest
      );
      const accessToken = response.accessToken;

      const pipelineUrl = this.pipelines.find((p) => p.id === pipelineId)
        ._links.self.href;
      const projectId = pipelineUrl.split("/")[4];

      await runPipeline(accessToken, projectId, pipelineId);

      // Directly assign the message without using $set
      this.messages = {
        ...this.messages,
        [pipelineId]: {
          text: "Pipeline started successfully!",
          type: "success",
        },
      };
    } catch (error) {
      console.error("Failed to run pipeline:", error);

      // Directly assign the error message without using $set
      this.messages = {
        ...this.messages,
        [pipelineId]: {
          text: "Failed to start the pipeline. Please try again.",
          type: "error",
        },
      };
    }
  },
},

};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Roboto:wght@400;500;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.header {
  background: #0e223b;
  color: #fff;
}

.top-bar {
  background: #ffffff;
  padding: 20px 0;
  text-align: center;
}

.top-bar .logo {
  text-align: left;
}

.top-bar .logo img {
  width: 150px;
}

.nav-bar {
  background: #0e223b;
  padding: 10px 0;
}

.nav-bar a {
  color: #fff;
  margin: 0 15px;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-bar a:hover {
  color: #38b35f;
}

.pipelines-list {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.pipeline-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1c3a63;
  color: #fff;
  font-size: 1rem;
  padding: 20px 30px;
  border-radius: 8px;
  margin-bottom: 20px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  font-family: "Roboto", sans-serif;
}

.pipeline-item:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
}

.pipeline-info {
  flex-grow: 1;
  text-align: left;
}

.pipeline-name {
  font-weight: bold;
}

.pipeline-folder {
  font-style: italic;
  margin-left: 10px;
}

.pipeline-actions {
  display: flex;
  align-items: center;
}

.run-pipeline-button {
  background-color: #38b35f;
  color: white;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.run-pipeline-button:hover {
  background-color: #2a2c53;
}

.message {
  margin-left: 20px;
  padding: 10px;
  border-radius: 4px;
  font-size: 1rem;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
}
</style>
